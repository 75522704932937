import { fetchCurrentUser } from '@/services/apiService'
import { useUserStore } from '@/stores/user'
import { toast } from 'vue3-toastify'

export function authGuard(to, from, next) {
  const userStore = useUserStore()

  if (to.meta.authRequired && !userStore.getUser()) {
    // Redirect to login if not authenticated
    // we should load user on every F5, the request will be authenticated using cookie. If fails, we redirect to login
    fetchCurrentUser()
      .then((user) => {
        userStore.setUser(user)
        if (to.meta.adminOnly && user?.role !== 'gear_master') {
          toast.error('You are not authorized to access this page')
          next({ path: '/' })
        } else {
          next()
        }
      })
      .catch((err) => {
        console.log(err)
        next({ path: '/login', query: { redirect: to.fullPath } })
      })
  } else {
    // Proceed to the route
    next()
  }
}
