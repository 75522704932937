import type { ModelName } from '@/enums'
import axios, { AxiosError, type AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import qs from 'qs'

axios.defaults.baseURL = import.meta.env.VITE_STRAPI_URL
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
const apiClient = axios.create({
  baseURL: axios.defaults.baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// before login or first request in SPA we must obtain csrf cookie
export const getCsrfToken = async () => {
  await axios.get('/sanctum/csrf-cookie')
}

const baseErrorHandler = (err: AxiosError) => {
  if (err.status === 400 && err.response?.data) {
    return err.response.data
  } else {
    throw err
  }
}

export const loginUser = async (data: {
  email: string
  password: string
}): Promise<{ message: string; user: App.Models.User; apiToken: string | null }> => {
  await getCsrfToken() // before login or first login we must obtain csrf cookie and after that we can sent request
  try {
    return await apiClient.post('/api/login', { ...data, create_token: false })
  } catch (error) {
    throw error
  }
}

export const fetchCurrentUser = async (): Promise<App.Models.User> => {
  await getCsrfToken() // before login or first login we must obtain csrf cookie and after that we can sent request
  try {
    const response = await apiClient.get('/api/me')
    return response.data
  } catch (error) {
    throw error
  }
}

export const logoutUser = async (): Promise<{ message: string }> =>
  apiClient.post('/api/logout').then((respnse) => respnse.data)

/**
 * Build query for fetching data. Each filter/sort/include must be firstly allowed on backend in certain controller in index method (Example can be found in ItemController)
 *
 * @param {object} filters The key of object is the name of field to filter, value of that key is the value that will be used for filtering. Values can have special special operator (e.g. '>' at the begging creates gte) as well as ',' (comma) to create OR values
 * @param {string[]} sorting E.g. to sort by descending id and secondary name: ['-id', 'name'] or sort by order asc, name secondary: ['order', 'name']
 * @param {string[]} includes Includes one or more relationship, e.g. for reservation ['reservedGroupsDetails.group.category', 'event']
 */
export const buildQuery = (
  filters: { [key: string]: string | number | number[] | string[] },
  sorting: string[] = [],
  includes: string[] = [],
) => {
  return qs.stringify({
    filter: filters,
    sort: sorting,
    include: includes,
  })
}

/*
  generic template methods, so we dont need to write a custom function for every model

  example usage:
  index<App.Models.Group>(ModelName.Group).then(res => console.log(res))
  show<App.Models.Group>(ModelName.Group, 45).then(res => console.log(res)) // ide will know that res is type of App.Models.Group
  indexPaginated<App.Models.Reservation>(ModelName.Reservation).then(res => console.log(res.data[0].reservation_name))

*/

/**
 * Beaware that some models can be paginated
 * Returns array of all models matching query filtering (see method buildQuery)
 */
export const index = async function <T>(model: ModelName, query: string = ''): Promise<T[]> {
  return apiClient.get(`/api/${model}?${query}`).then((response) => response.data)
}

export const indexPaginated = async function <T>(
  model: ModelName,
  query: string = '',
): Promise<App.ApiPaginate<T>> {
  return apiClient.get(`/api/${model}?${query}`).then((response) => response.data)
}

/**
 * Get detail of single model
 * @param {string[]} includes Name of relations that should be included. Must be enabled in BE.
 */
export const show = async function <T>(
  model: ModelName,
  id: number | string,
  query: string = '',
): Promise<T> {
  return apiClient.get(`/api/${model}/${id}?${query}`).then((response) => response.data)
}

/**
 * @param {object} data Please use or define accepted request in src/requests.d.ts
 */
export const create = async function <T>(
  model: ModelName,
  data: object,
  config: AxiosRequestConfig = {} as AxiosRequestConfig,
): Promise<T> {
  return apiClient.post(`/api/${model}`, data, config).then((response) => response.data)
}

/** currently works only with media */
export const createMany = async function <T>(
  model: ModelName,
  data: object,
  config: AxiosRequestConfig = {} as AxiosRequestConfig,
): Promise<T[]> {
  return apiClient.post(`/api/${model}`, data, config).then((response) => response.data)
}

/**
 * @param {object} data Please use or define accepted request in src/requests.d.ts
 */
export const update = async function <T>(
  model: ModelName,
  id: number | string,
  data: object,
  config: AxiosRequestConfig = {} as AxiosRequestConfig,
  query: string = '',
): Promise<T> {
  return apiClient
    .put(`/api/${model}/${id}?${query}`, data, config)
    .then((response) => response.data)
}

export const deleteRow = async function (model: ModelName, id: number | string): Promise<null> {
  return apiClient.delete(`/api/${model}/${id}`).then((response) => response.data)
}

export const saveOrder = async function (
  model: ModelName,
  data: { ordered_ids: number[] },
): Promise<{ success: boolean }> {
  return apiClient.post(`/api/${model}/save-order`, data).then((response) => response.data)
}

export const fetchEvents = async (query: string = ''): Promise<App.Models.Event[]> =>
  apiClient.get(`/api/events?${query}`).then((response) => response.data)
export const fetchLocations = async (query: string = ''): Promise<App.Models.Location[]> =>
  apiClient.get(`/api/locations?${query}`).then((response) => response.data)
export const fetchUsers = async (query: string = ''): Promise<App.Models.User[]> =>
  apiClient.get(`/api/users?${query}`).then((response) => response.data)
export const fetchTemplates = async (query: string = ''): Promise<App.Models.Template[]> =>
  apiClient.get(`/api/templates?${query}`).then((response) => response.data)
export const fetchGroups = async (query: string = 'include=cover'): Promise<App.Models.Group[]> =>
  apiClient.get(`/api/groups?${query}`).then((response) => response.data)
export const fetchCategories = async (query: string = ''): Promise<App.Models.Category[]> =>
  apiClient.get(`/api/categories?${query}`).then((response) => response.data)

export const fetchEventsPaginated = async function (
  query: string = '',
): Promise<App.ApiPaginate<App.Models.Event>> {
  return apiClient.get(`/api/events/paginated?${query}`).then((response) => response.data)
}

export const fetchReservationsNonPaginated = async (
  query: string = '',
): Promise<App.Models.Reservation[]> =>
  apiClient.get(`/api/reservations/all?${query}`).then((response) => response.data)

export const fetchItemsNonPaginated = async (query: string = ''): Promise<App.Models.Item[]> =>
  apiClient.get(`/api/items/all?${query}`).then((response) => response.data)

export const getReservation = async (
  id: number,
  query: string = '',
): Promise<App.Models.ReservationWithGear> =>
  apiClient.get(`/api/reservations/${id}?` + query).then((response) => response.data)

export const pickupItem = async (
  id: string | number,
  data: App.Request.PickupItemRequest,
): Promise<
  App.Response.ItemPickupReturnFailResponse | App.Response.ItemPickupReturnSuccessResponse
> =>
  apiClient
    .post(`/api/items/pickup/${id}`, data)
    .then((response) => response.data)
    .catch(baseErrorHandler)

export const verifyExpressPickupItem = async (
  id: string | number,
  data: App.Request.VerifyExpressPickupItemRequest,
): Promise<
  App.Response.ItemPickupReturnFailResponse | App.Response.ItemPickupReturnSuccessResponse
> =>
  apiClient
    .post(`/api/items/verify-express-pickup/${id}`, data)
    .then((response) => response.data)
    .catch(baseErrorHandler)

export const returnItem = async (
  id: string | number,
  data: App.Request.ReturnItemRequest,
): Promise<
  App.Response.ItemPickupReturnFailResponse | App.Response.ItemPickupReturnSuccessResponse
> =>
  apiClient
    .post(`/api/items/return/${id}`, data)
    .then((response) => response.data)
    .catch(baseErrorHandler)

export const getItemMergedLogs = async (
  id: string | number,
  query: string = '',
): Promise<App.Paginate<App.Response.ItemMergedLogResponse>> => {
  return apiClient.get(`/api/items/logs/${id}?${query}`).then((response) => response.data)
}

export const getItemLogEntries = async (
  id: string | number,
  query: string = '',
): Promise<App.Models.ItemLogEntry[]> => {
  return apiClient
    .get(`/api/item-log-entries/get-for-item/${id}?${query}`)
    .then((response) => response.data)
}

export const generateQrFile = async (ids: number[]): Promise<{ success: true; url: string }> => {
  return apiClient.post(`/api/items/qr-file`, { items: ids }).then((response) => response.data)
}

export const getLatestItemLogEntries = async (
  id: string | number,
  query: string = '',
): Promise<App.Models.ItemLogEntry[]> => {
  return apiClient
    .get(`/api/item-log-entries/get-latests-for-item/${id}?${query}`)
    .then((response) => response.data)
}

export const getOverviewByPeak = async (
  date_from: Date,
  date_to: Date,
  location_id: number,
  exclude_reservations: number[] = [],
  groups: number[] = [],
): Promise<App.Response.OverviewByPeak> => {
  return apiClient
    .post('/api/groups/get-overview-by-peak', {
      date_from: dayjs(date_from).toISOString(),
      date_to: dayjs(date_to).toISOString(),
      location_id,
      exclude_reservations,
      groups,
    })
    .then((response) => response.data)
}

export const getOverlappingReservations = async (
  date_from: Date,
  date_to: Date,
  location_id: number,
  include_returned: boolean = false,
): Promise<App.Response.OverlappingReservations> => {
  return apiClient
    .post('/api/reservations/get-overlapping-reservations', {
      date_from: dayjs(date_from).toISOString(),
      date_to: dayjs(date_to).toISOString(),
      location_id,
      include_returned,
    })
    .then((response) => response.data)
}

/**
 * @throws {AxiosError<App.Response.ValidationError>}
 */
export const validateBooking = async (
  data: App.Request.CreateBookingRequest,
): Promise<App.Response.CreateBookingValidationSuccess | App.Response.CreateBookingFail> =>
  apiClient
    .post('/api/bookings/validate-create', data)
    .then((response) => response.data)
    .catch(baseErrorHandler)
/**
 * @throws {AxiosError<App.Response.ValidationError>}
 */
export const createBooking = async (
  data: App.Request.CreateBookingRequest,
): Promise<App.Response.CreateBookingSuccess | App.Response.CreateBookingFail> =>
  apiClient
    .post('/api/bookings/create', data)
    .then((response) => response.data)
    .catch(baseErrorHandler)

/**
 * @throws {AxiosError<App.Response.ValidationError>}
 */
export const validateBookingUpdate = async (
  id: number,
  data: App.Request.CreateBookingRequest,
): Promise<App.Response.CreateBookingValidationSuccess | App.Response.CreateBookingFail> =>
  apiClient
    .post(`/api/bookings/${id}/validate-edit`, data)
    .then((response) => response.data)
    .catch(baseErrorHandler)
/**
 * @throws {AxiosError<App.Response.ValidationError>}
 */
export const updateBooking = async (
  id: number,
  data: App.Request.CreateBookingRequest,
): Promise<App.Response.CreateBookingSuccess | App.Response.CreateBookingFail> =>
  apiClient
    .post(`/api/bookings/${id}/edit`, data)
    .then((response) => response.data)
    .catch(baseErrorHandler)

/**
 * @throws {AxiosError<App.Response.ValidationError>}
 */
export const deleteBooking = async (
  id: number,
): Promise<App.Response.CreateBookingSuccess | App.Response.CreateBookingFail> =>
  apiClient.delete(`/api/bookings/${id}`).then((response) => response.data)

export const getForcebookData = async (
  data: App.Request.GetForcebookDataRequest,
): Promise<App.Response.GetForcebookDataResponse> =>
  apiClient
    .post('/api/bookings/forcebook-data', data)
    .then((response) => response.data)
    .catch(baseErrorHandler)

export const willPickupLaterReservation = async (id: number): Promise<App.Models.Reservation> =>
  apiClient.post(`/api/reservations/${id}/will-pickup-later`, {}).then((response) => response.data)

export const releaseItemsReservation = async (id: number): Promise<App.Models.Reservation> =>
  apiClient.post(`/api/reservations/${id}/release-items`, {}).then((response) => response.data)

export const getForcebookReservations = async (id: number): Promise<App.Models.Reservation[]> =>
  apiClient
    .post(`/api/reservations/${id}/get-forcebook-reservations`)
    .then((response) => response.data)

export const getReservationMergedLogs = async (
  id: string | number,
  query: string = '',
): Promise<App.Response.ReservationMergedLogResponse[]> => {
  return apiClient.get(`/api/reservations/logs/${id}?${query}`).then((response) => response.data)
}

export const exportReservation = async (
  reservationId: number,
): Promise<{ success: true; url: string }> => {
  return apiClient
    .get(`/api/reservation-export/${reservationId}/reservation`)
    .then((response) => response.data)
}

export const exportPickedItems = async (
  reservationId: number,
): Promise<{ success: true; url: string }> => {
  return apiClient
    .get(`/api/reservation-export/${reservationId}/picked-items`)
    .then((response) => response.data)
}

export const exportReturnedItems = async (
  reservationId: number,
): Promise<{ success: true; url: string }> => {
  return apiClient
    .get(`/api/reservation-export/${reservationId}/returned-items`)
    .then((response) => response.data)
}

export const getReleasedItemsData = async (data: {
  date_from: string
  date_to: string
  location_id: number[]
}): Promise<App.Response.ReleasedItemsResponse[]> => {
  return apiClient.post(`/api/released-items/get-data`, data).then((response) => response.data)
}

export const getGearcheckGroupItems = async (data: {
  location_id: number
  sublocation_id: number
}): Promise<App.Response.GearcheckGroupItemsResponse[]> => {
  return apiClient.post(`/api/gearchecks/get-data`, data).then((response) => response.data)
}

/** returns max 3 logs (item history) per item */
export const getLatestLogsForItems = async (data: {
  items: number[]
}): Promise<App.Response.GearcheckLatestItemLogsResponse[]> => {
  return apiClient
    .post(`/api/gearchecks/get-latest-logs-for-items`, data)
    .then((response) => response.data)
}
