<script setup lang="ts">
import router from '@/router'
import { useUserStore } from '@/stores/user'
import {
  HomeIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  BeakerIcon,
  PlusCircleIcon,
  ArrowUpTrayIcon,
  ArrowDownTrayIcon,
  CheckCircleIcon,
  CircleStackIcon,
  Bars3CenterLeftIcon,
  TagIcon,
  CameraIcon,
  ShieldExclamationIcon,
} from '@heroicons/vue/24/outline'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import MenuItem from './MenuItem.vue'
import { Bars3Icon } from '@heroicons/vue/24/solid'

const emit = defineEmits(['collapsed'])
const collapsed = ref(false)
const mobileShow = ref(false)
const route = useRoute()
const userStore = useUserStore()
const navigation = computed(() => [
  {
    name: 'Dashboard',
    route: { name: 'Home' },
    icon: HomeIcon,
    show: true,
  },
  {
    name: 'Create new reservation',
    route: { name: 'CreateBooking' },
    icon: PlusCircleIcon,
    show: true,
  },
  {
    name: 'Styleguide',
    route: { name: 'Styleguide' },
    icon: BeakerIcon,
    show: true,
  },
  {
    name: 'Pickup',
    route: { name: 'Pickup' },
    icon: ArrowUpTrayIcon,
    show: true,
  },
  {
    name: 'Return',
    route: { name: 'Return' },
    icon: ArrowDownTrayIcon,
    show: true,
  },
  {
    name: 'Timeline',
    route: { name: 'ViewTimeline' },
    icon: Bars3CenterLeftIcon,
    show: true,
  },
  {
    name: 'Gearlist',
    route: { name: 'Gearlist' },
    icon: CameraIcon,
    show: true,
  },
  {
    name: 'Released items',
    route: { name: 'ViewReleasedItems' },
    icon: ShieldExclamationIcon,
    show: true,
  },
  {
    name: 'Data manage',
    route: { name: 'DataManage' },
    icon: CircleStackIcon,
    show: true,
  },
  {
    name: 'Gear check',
    route: { name: 'Gearcheck' },
    icon: CheckCircleIcon,
    show: true,
  },
  {
    name: 'Tagging',
    route: { name: 'Tagging' },
    icon: TagIcon,
    show: true,
  },
])

watch(
  () => route.fullPath, // Watching fullPath to detect route changes
  () => {
    mobileShow.value = false
  },
)

function toggleMenu() {
  console.log('clicked toggler')
  mobileShow.value = !mobileShow.value
}
function toggleCollapse() {
  collapsed.value = !collapsed.value
  emit('collapsed', collapsed.value)
}
</script>

<template>
  <div class="flex flex-col flex-1 min-h-0 transition-all bg-[#353339] z-50">
    <div
      class="flex flex-col flex-1 pt-5 pb-5 overflow-x-hidden overflow-y-auto lg:pb-4 z-50 bg-[#353339]"
    >
      <div class="flex items-center justify-between flex-shrink-0 px-4">
        <router-link id="button-home" :to="{ name: 'Home' }">
          <h1
            class="text-3xl font-bold text-center text-white uppercase transition-all whitespace-nowrap"
            :class="[collapsed ? '-mx-2' : 'mx-0']"
          >
            <span class="text-primary">G<template v-if="!collapsed">ear</template></span
            ><template v-if="!collapsed">&nbsp;</template>R<template v-if="!collapsed"
              >ental</template
            >
          </h1>
        </router-link>
        <div id="toggle-menu" class="block text-xl text-white lg:hidden" @click="toggleMenu">
          <Bars3Icon class="w-8 h-8" />
        </div>
      </div>
      <nav
        style="z-index: 100"
        class="flex-1 px-2 mt-5 space-y-1"
        :class="{
          'hidden lg:block': !mobileShow,
          open: mobileShow,
        }"
      >
        <router-link
          v-for="item in navigation"
          :key="item.name"
          v-slot="{ href, navigate, isActive }"
          :to="item.route"
          custom
        >
          <MenuItem
            v-if="item.show"
            v-tooltip="{ content: collapsed ? item.name : null, placement: 'right' }"
            :href="href"
            :active="isActive"
            class="whitespace-nowrap"
            @click="navigate"
          >
            <component
              :is="item.icon"
              :class="[
                isActive ? 'text-dark' : 'text-gray-400 group-hover:text-gray-300',
                'mr-3 h-6 w-6 flex-shrink-0',
              ]"
              aria-hidden="true"
            />
            <template v-if="!collapsed">
              {{ item.name }}
            </template>
          </MenuItem>
        </router-link>
        <div>
          <MenuItem
            v-tooltip="{ content: collapsed ? 'Expand menu' : null, placement: 'right' }"
            :href="'#'"
            :active="false"
            class="hidden lg:flex"
            @click="toggleCollapse"
          >
            <component
              :is="collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon"
              :class="['text-gray-400 group-hover:text-gray-300', 'mr-3 h-6 w-6 flex-shrink-0']"
              aria-hidden="true"
            />
            <span v-if="!collapsed" class="whitespace-nowrap"> Collapse menu </span>
          </MenuItem>
        </div>
      </nav>
    </div>
    <div
      class="p-4"
      :class="{
        'hidden lg:block': !mobileShow,
      }"
    ></div>
    <div
      v-if="!collapsed"
      class="flex flex-shrink-0 p-4 bg-elevation2"
      :class="{
        'hidden lg:block': !mobileShow,
      }"
    >
      <a href="#" class="flex-shrink-0 block w-full group" v-if="userStore.user">
        <div class="flex flex-col items-center justify-center">
          <router-link
            v-if="false"
            :to="{ name: 'UserDetail', params: { id: userStore.user?.id } }"
          >
            <div>
              <img
                class="inline-block w-16 h-16 rounded-full"
                :src="
                  userStore.user?.user_info?.image ||
                  'https://cdn.slideslive.com/data/users/avatar.png'
                "
                title=""
                alt=""
              />
            </div>
          </router-link>
          <div class="flex flex-col items-center">
            <p class="text-sm font-medium text-white">
              {{ userStore.user.preview_name ?? userStore.user.name }}
            </p>
            <div class="flex items-end justify-center align-center">
              <router-link
                class="p-1 text-xs font-medium text-gray-300 hover:text-gray-200 hover:underline hover:underline-offset-2"
                :to="{ name: 'Settings' }"
              >
                Settings
              </router-link>
              <router-link
                :to="{ name: 'Logout' }"
                class="p-1 text-xs font-medium text-gray-300 hover:text-gray-200 hover:underline hover:underline-offset-2"
              >
                Logout
              </router-link>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
