import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const user = ref<App.Models.User | null>(null)

  // Action to set the current user
  const setUser = (userData: App.Models.User) => {
    user.value = userData
  }

  // Action to clear the user (e.g., on logout)
  const logout = () => {
    user.value = null
    window.localStorage.removeItem('slgr_token')
  }

  // Getter to retrieve the current user
  const getUser = () => user.value

  const isGearMaster = () => user.value?.role === 'gear_master'

  return {
    user,
    setUser,
    logout,
    getUser,
    isGearMaster,
  }
})
