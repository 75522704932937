import { buildQuery } from '@/services/apiService'

export const decodeFilter = (query: { [key: string]: string | undefined }) => {
  const filter = {} as { [key: string]: string | number | boolean | Array<number | string> }
  for (const key of Object.keys(query)) {
    if (key === 'page' || key === 'perPage' || key === 'q' || key === 'edit') continue
    const value = query[key]
    if (!value) continue

    if (value.toString().match(/^[0-9]+$/)) {
      filter[key] = parseInt(value)
      continue
    }

    if (value.toString() === 'false') {
      filter[key] = false
      continue
    }

    if (value.toString() === 'true') {
      filter[key] = true
      continue
    }

    if (String(value).startsWith('[') && String(value).endsWith(']')) {
      const splittedValue = String(value).replace('[', '').replace(']', '').split(',')
      filter[key] = splittedValue.map((item) => {
        if (item.toString().match(/^[0-9]+$/)) {
          return parseInt(item)
        }
        return item
      })
      continue
    }

    if (!value) continue
    filter[key] = value
  }

  return filter
}

export const cleanFilter = (filter: {
  [key: string]: string | number | boolean | Array<number | string>
}) => {
  for (const key of Object.keys(filter)) {
    if (key === 'page' || key === 'perPage' || key === 'q' || key === 'edit') continue
    if (filter[key] === null || filter[key] === undefined || filter[key] === '') {
      delete filter[key]
    } else if (typeof filter[key] === 'object' && Object.keys(filter[key]).length > 0) {
      if (Object.keys(filter[key]).find((k) => !k.toString().match(/^[0-9]+$/))) {
        continue
      }
      filter[key] = '[' + Object.values(filter[key]).join(',') + ']'
    }
  }
  return filter
}

export const combineFilterWithDefaultStructure = (
  filter: { [key: string]: string | number | boolean | Array<number | string> },
  default_structure: { [key: string]: string | number | boolean | Array<number | string> },
) => {
  const f = { ...default_structure }
  for (const key of Object.keys(filter)) {
    f[key] = filter[key]
  }
  return f
}

export const transformFilterObjectToQueryReady = (
  filter: { [key: string]: string | number | boolean | Array<number | string> },
  onlyKeys: string[] = [],
) => {
  const data = {} as { [key: string]: string }
  for (const key of Object.keys(filter)) {
    if (key === 'page' || key === 'perPage' || key === 'q' || key === 'edit') continue
    if (filter[key] === null || filter[key] === undefined || filter[key] === '') continue
    if (onlyKeys.length && !onlyKeys.includes(key)) continue
    if (Array.isArray(filter[key])) {
      if (filter[key].length === 0) continue
      data[key] = filter[key].join(',')
    } else {
      data[key] = String(filter[key])
    }
  }

  return data
}
