import { createApp } from 'vue'
import { createPinia } from 'pinia'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { VueQrcodeReader } from 'vue-qrcode-reader'
import 'vue-json-pretty/lib/styles.css'
import VueClickAway from 'vue3-click-away'
import * as Sentry from '@sentry/vue'

import './style.css'

import App from './App.vue'
import router from './router'

const app = createApp(App)

const sentryDsn = import.meta.env.VITE_SENTRY_DSN
if (sentryDsn) {
  Sentry.init({
    app,
    dsn: sentryDsn,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /*"localhost", /^https:\/\/gear\.slideslive\.com\//, /^https:\/\/gear-api\.slideslive\.com\//*/
    ],
    // Session Replay
    replaysSessionSampleRate: 0, // 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(VueClickAway)
app.use(createPinia())
app.use(router)
app.use(FloatingVue)
app.use(VueQrcodeReader)
app.use(Vue3Toastify, {
  // TODO use dark mode
  autoClose: 3000,
  clearOnUrlChange: false,
  // ...
} as ToastContainerOptions)

app.mount('#app')
