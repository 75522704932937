<script setup lang="ts">
import { ReservationStatus } from '@/enums'
import { PencilIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue'
import BadgeComponent from './forms/BadgeComponent.vue'
import Button from './forms/Button.vue'

const props = withDefaults(
  defineProps<{
    reservation: App.Models.Reservation
    users: App.Models.User[]
    locations: App.Models.Location[]
    showReturn?: boolean
    showPickup?: boolean
    showEdit?: boolean
  }>(),
  {
    showReturn: true,
    showPickup: true,
    showEdit: true,
  },
)

const dateString = computed(() => {
  const start = new Date(props.reservation.start_utc)
  const end = new Date(props.reservation.end_utc)
  if (start.getFullYear() === end.getFullYear()) {
    return `${start.toLocaleDateString(['cs'], {
      day: 'numeric',
      month: start.getMonth() === end.getMonth() ? undefined : 'numeric',
    })} - ${end.toLocaleDateString(['cs'])}`
  } else {
    return `${start.toLocaleDateString(['cs'])} - ${end.toLocaleDateString(['cs'])}`
  }
})

const isReturned = props.reservation.status === ReservationStatus.Returned
const hasAnyPickedItems = !!props.reservation.first_pickup_at
const pickupLocation = computed(() => {
  return props.locations.find((location) => props.reservation.location_id === location.id)
})
const userPreview = computed(() => {
  const user = props.users.find((user) => user.id === props.reservation.user_id)
  if (user) {
    return user.preview_name ?? user.name
  }
  return ''
})
</script>

<template>
  <div class="px-4 py-3 rounded-lg shadow bg-elevation2">
    <div class="w-full">
      <div class="space-y-3">
        <div class="relative pr-10">
          <router-link
            class="text-lg font-bold cursor-pointer hover:underline"
            title="Reservation detail"
            :to="{ name: 'ReservationDetail', params: { id: reservation.id } }"
          >
            {{ reservation.reservation_name }}
          </router-link>
          <router-link
            v-if="!isReturned && showEdit"
            class="absolute top-0 right-0 transition-all opacity-75 hover:opacity-100"
            :to="{
              name: 'ReservationDetail',
              params: { id: reservation.id },
              query: { edit: 'true' },
            }"
          >
            <PencilIcon v-tooltip="'Edit reservation'" class="w-6 h-6" />
          </router-link>
        </div>
        <div class="-mt-1.5 -mb-1.5 text-xs font-bold text-zinc-400">{{ userPreview }}</div>

        <div class="pb-0 text-sm text-zinc-200">
          {{ dateString }}
          <div v-if="reservation.last_item_return_at" class="-mt-0.5 text-xs text-zinc-300">
            Last item return:
            {{ new Date(reservation.last_item_return_at).toLocaleString('cs-CZ') }}
          </div>
        </div>

        <div class="flex gap-2">
          <div class="w-1/2 xl:w-5/12">
            <div class="">
              <div class="w-full">
                <BadgeComponent
                  v-if="
                    new Date(reservation.end_utc) < new Date() && !isReturned && hasAnyPickedItems
                  "
                  class-list="bg-orange-400 mr-2 mb-2 2xl:mb-0 text-white"
                  text="Missed return"
                ></BadgeComponent>
              </div>
              <div class="w-full">
                <BadgeComponent
                  v-if="isReturned"
                  class-list="bg-green-400 mr-2 mb-2 2xl:mb-0 text-white"
                  text="Returned"
                ></BadgeComponent>
              </div>
              <div class="w-full">
                <BadgeComponent
                  :text="pickupLocation.name"
                  v-if="pickupLocation"
                  class-list="bg-blue-400 text-white whitespace-nowrap"
                />
              </div>
            </div>
          </div>
          <div class="w-1/2 xl:w-7/12">
            <div
              v-if="!isReturned && (showReturn || showPickup)"
              class="inline-flex flex-wrap justify-end md:w-full gap-y-1 gap-x-2"
            >
              <router-link
                v-if="showPickup"
                :to="{ name: 'Pickup', params: { reservationId: reservation.id } }"
              >
                <Button variant="primary-md" class="md:mb-4 md:w-full xl:mb-0 xl:w-auto"
                  >Pick up</Button
                >
              </router-link>
              <router-link
                v-if="showReturn"
                :to="{ name: 'Return', params: { reservationId: reservation.id } }"
              >
                <Button
                  :variant="showPickup ? 'info-md' : 'primary-md'"
                  class="md:w-full xl:w-auto"
                  :disabled="!hasAnyPickedItems"
                  :tooltip="!hasAnyPickedItems ? 'No items were picked up yet' : null"
                  >Return</Button
                >
              </router-link>
            </div>
            <div v-else class="flex justify-end">
              <router-link :to="{ name: 'ReservationDetail', params: { id: reservation.id } }">
                <Button variant="primary-md" class="mr-2 md:mb-4 md:w-full xl:mb-0 xl:w-auto"
                  >Detail</Button
                >
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
