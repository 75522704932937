import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from './AuthGuard'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Login',
      path: '/login',
      component: () => import('../views/User/Login.vue'),
      meta: { authRequired: false, adminOnly: false, layout: 'auth' },
    },
    {
      name: 'Logout',
      path: '/logout',
      component: () => import('../views/User/Logout.vue'),
      meta: { authRequired: false, adminOnly: false, layout: 'auth' },
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Styleguide.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'CreateBooking',
      path: '/create',
      component: () => import('../views/Reservation/CreateReservation.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Pickup',
      path: '/pickup/:reservationId?',
      component: () => import('../views/Pickup.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Return',
      path: '/return/:reservationId?',
      component: () => import('../views/Return.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Gearlist',
      path: '/gear-list',
      component: () => import('../views/Gearlist.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Settings',
      path: '/settings',
      component: () => import('../views/SettingsView.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'DataManage',
      path: '/data',
      component: () => import('../views/DataManage/DataIndex.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ViewTimelineGroup',
      path: '/view/timeline/:group',
      component: () => import('../views/EmptyView.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ViewTimeline',
      path: '/view/timeline',
      component: () => import('../views/EmptyView.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ViewReleasedItems',
      path: '/view/released-items',
      component: () => import('../views/ReleasedItemsView.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Reservations',
      path: '/bookings',
      component: () => import('../views/DataManage/Reservation/ReservationList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ReservationDetail',
      path: '/bookings/:id',
      component: () => import('../views/DataManage/ReservationDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Users',
      path: '/users',
      component: () => import('../views/DataManage/User/UserList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'UserDetail',
      path: '/users/:id',
      component: () => import('../views/DataManage/User/UserDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Categories',
      path: '/categories',
      component: () => import('../views/DataManage/Category/CategoryList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'CategoryCreate',
      path: '/categories/add',
      component: () => import('../views/DataManage/Category/CategoryAdd.vue'),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'CategoryDetail',
      path: '/categories/:id',
      component: () => import('../views/DataManage/Category/CategoryDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Groups',
      path: '/groups',
      component: () => import('../views/DataManage/Group/GroupsList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'GroupCreate',
      path: '/groups/add',
      component: () => import('../views/DataManage/Group/GroudAdd.vue'),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'GroupDetail',
      path: '/groups/:id',
      component: () => import('../views/DataManage/Group/GroupDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Tags',
      path: '/tags',
      component: () => import('../views/DataManage/Tag/TagList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'TagCreate',
      path: '/tags/add',
      component: () => import('../views/DataManage/Tag/TagAdd.vue'),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'TagDetail',
      path: '/tags/:id',
      component: () => import('../views/DataManage/Tag/TagDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Tagging',
      path: '/tagging',
      component: () => import('../views/TaggingView.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Items',
      path: '/gear',
      component: () => import('../views/DataManage/Item/ItemsList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ItemCreate',
      path: '/gear/add',
      component: () => import('../views/DataManage/Item/ItemAdd.vue'),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'ItemCreateBulk',
      path: '/gear/bulk-create',
      component: () => import('../views/DataManage/Item/ItemBulkAdd.vue'),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'ItemDetail',
      path: '/gear/:id',
      component: () => import('../views/DataManage/Item/ItemDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Events',
      path: '/events',
      component: () => import('../views/DataManage/EventList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Locations',
      path: '/locations',
      component: () => import('../views/DataManage/Location/LocationList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'LocationCreate',
      path: '/locations/add',
      component: () => import('../views/DataManage/Location/LocationAdd.vue'),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'LocationDetail',
      path: '/locations/:id',
      component: () => import('../views/DataManage/Location/LocationDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Sublocations',
      path: '/sublocations',
      component: () => import('../views/DataManage/Sublocation/SublocationList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'SublocationCreate',
      path: '/sublocations/add',
      component: () => import('../views/DataManage/Sublocation/SublocationAdd.vue'),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'SublocationDetail',
      path: '/sublocations/:id',
      component: () => import('../views/DataManage/Sublocation/SublocationDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Templates',
      path: '/templates',
      component: () => import('../views/DataManage/Template/TemplateList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'TemplateCreate',
      path: '/templates/add',
      component: () => import('../views/DataManage/Template/TemplateAdd.vue'),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'TemplateDetail',
      path: '/templates/:id',
      component: () => import('../views/DataManage/Template/TemplateDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Gearcheck',
      path: '/gearcheck',
      component: () => import('../views/GearcheckView.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'GearcheckReservation',
      path: '/gearcheck/:reservation',
      component: () => import('../views/GearcheckView.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'InventoryChecks',
      path: '/gearchecks',
      component: () => import('../views/DataManage/Gearcheck/GearcheckList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'InventoryCheckDetail',
      path: '/gearchecks/:id',
      component: () => import('../views/DataManage/Gearcheck/GearcheckDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'InventoryCheckItems',
      path: '/gearcheck-items',
      component: () => import('../views/DataManage/GearcheckItem/GearcheckItemList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'InventoryCheckItemDetail',
      path: '/gearcheck-items/:id',
      component: () => import('../views/DataManage/GearcheckItem/GearcheckItemDetail.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'ItemHistory',
      path: '/item-history',
      component: () => import('../views/DataManage/ItemHistoryList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ItemLogEntries',
      path: '/item-log-entries',
      component: () => import('../views/DataManage/ItemLogsList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Logs',
      path: '/logs',
      component: () => import('../views/DataManage/LogsList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'MailLogs',
      path: '/mail-logs',
      component: () => import('../views/DataManage/MailLogsList.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'ApplicationSettings',
      path: '/application-settings',
      component: () => import('../views/EmptyView.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    // todo remove
    {
      name: 'MediaTest',
      path: '/media-test',
      component: () => import('../views/MediaTestView.vue'),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      path: '/:path(.*)',
      component: () => import('../views/NotFound.vue'),
      meta: { authRequired: false, adminOnly: false, layout: 'base' },
    },
  ],
})

router.beforeEach(authGuard)

export default router
